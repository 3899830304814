import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showButtons.edit)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":"","to":{ name: _vm.link, params: { id: _vm.id }}}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.customActionEdit(_vm.id)}}},[_vm._v("mdi-pencil")])],1)]}}],null,false,2410732216)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.showButtons.delete)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDelete(_vm.id)}}},[_vm._v("mdi-delete")])],1)]}}],null,false,2109306662)},[_c('span',[_vm._v("Delete")])]):_vm._e(),(_vm.showButtons.cancel)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmCancel(_vm.id)}}},[_vm._v("mdi-flask-empty-off")])],1)]}}],null,false,472986665)},[_c('span',[_vm._v("Inactive")])]):_vm._e(),(_vm.showButtons.active)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmActive(_vm.id)}}},[_vm._v("mdi-account-reactivate-outline")])],1)]}}],null,false,1012834053)},[_c('span',[_vm._v("Active")])]):_vm._e(),(_vm.showButtons.approve)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmApprove(_vm.id)}}},[_vm._v("mdi-account-check-outline")])],1)]}}],null,false,2741790990)},[_c('span',[_vm._v("Approve")])]):_vm._e(),(_vm.showButtons.resetPassword)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmResetPassword(_vm.id)}}},[_vm._v("mdi-lock-reset")])],1)]}}],null,false,2661316383)},[_c('span',[_vm._v("Reset User Password")])]):_vm._e(),(_vm.showButtons.viewToDo)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.viewToDo(_vm.id)}}},[_vm._v("mdi-email-check-outline")])],1)]}}],null,false,4258616323)},[_c('span',[_vm._v("View To-Do")])]):_vm._e(),(_vm.showButtons.markDoneUnDone)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDoneUnDone(_vm.id)}}},[_vm._v("mdi-check")])],1)]}}],null,false,3475708347)},[_c('span',[_vm._v("Mark as Done")])]):_vm._e(),(_vm.showButtons.cloneToDo)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmCloneToDo(_vm.id)}}},[_vm._v("mdi-content-duplicate")])],1)]}}],null,false,2076189774)},[_c('span',[_vm._v("Clone this To-Do")])]):_vm._e(),(_vm.showButtons.assignSalesPerson)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.assignSalesPerson(_vm.id)}}},[_vm._v("mdi-clipboard-account-outline")])],1)]}}],null,false,862362053)},[_c('span',[_vm._v("Assign Sales Person")])]):_vm._e(),(_vm.showButtons.cancel2)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmCancel2(_vm.id)}}},[_vm._v("mdi-cancel")])],1)]}}],null,false,1842162036)},[_c('span',[_vm._v("Cancel")])]):_vm._e(),(_vm.showButtons.markStarted)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmMarkAsStarted(_vm.id)}}},[_vm._v("mdi-calendar-start-outline")])],1)]}}],null,false,812118290)},[_c('span',[_vm._v("Mark as Started")])]):_vm._e(),(_vm.showButtons.markFailed)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmMarkAsFailed(_vm.id)}}},[_vm._v("mdi-thumb-down-outline")])],1)]}}],null,false,3462875414)},[_c('span',[_vm._v("Mark as Incomplete")])]):_vm._e(),(_vm.showButtons.schedule)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmSchedule(_vm.id)}}},[_vm._v("mdi-calendar-start-outline")])],1)]}}],null,false,1214770065)},[_c('span',[_vm._v("Schedule")])]):_vm._e(),(_vm.showButtons.hold)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmHold(_vm.id)}}},[_vm._v("mdi-car-brake-hold")])],1)]}}],null,false,1892489449)},[_c('span',[_vm._v("On Hold")])]):_vm._e(),(_vm.showButtons.resume)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmResume(_vm.id)}}},[_vm._v("mdi-play-circle-outline")])],1)]}}],null,false,3302259817)},[_c('span',[_vm._v("Resume")])]):_vm._e(),(_vm.showButtons.check)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmCheck(_vm.id)}}},[_vm._v("mdi-text-box-check-outline")])],1)]}}],null,false,1371386755)},[_c('span',[_vm._v("Check")])]):_vm._e(),(_vm.showButtons.download)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDownload(_vm.id)}}},[_vm._v("mdi-file-download-outline")])],1)]}}],null,false,287655264)},[_c('span',[_vm._v("Download")])]):_vm._e(),(_vm.showButtons.notes)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmNotes(_vm.id)}}},[_vm._v("mdi-chat-alert-outline")])],1)]}}],null,false,2289384949)},[_c('span',[_vm._v("Communication for the customer")])]):_vm._e(),(_vm.showButtons.payments)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmPayments(_vm.id)}}},[_vm._v("mdi-account-credit-card")])],1)]}}],null,false,2992572919)},[_c('span',[_vm._v("Check Payments")])]):_vm._e(),(_vm.showButtons.viewPaymentsDetails)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.viewPaymentsDetails(_vm.id)}}},[_vm._v("mdi-credit-card-settings-outline")])],1)]}}],null,false,3940978563)},[_c('span',[_vm._v("View Payments Details")])]):_vm._e(),(_vm.showButtons.viewPhotos)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.viewPhotos(_vm.id)}}},[_vm._v("mdi-view-gallery-outline")])],1)]}}],null,false,718209795)},[_c('span',[_vm._v("View Photos")])]):_vm._e(),(_vm.showButtons.close)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmClose(_vm.id)}}},[_vm._v("mdi-check")])],1)]}}],null,false,3509643862)},[_c('span',[_vm._v("Close")])]):_vm._e(),(_vm.showButtons.view)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmView(_vm.id)}}},[_vm._v("mdi-history")])],1)]}}],null,false,1070646569)},[_c('span',[_vm._v("View")])]):_vm._e(),(_vm.showButtons.addClientConsideration)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmAddClientConsideration(_vm.id)}}},[_vm._v("mdi-face-agent")])],1)]}}],null,false,733589117)},[_c('span',[_vm._v("Add Client Considerations")])]):_vm._e(),(_vm.showButtons.checkSignature)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"btnActions",attrs:{"icon":"","small":"","outlined":""}},on),[_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.confirmCheckSignature(_vm.id)}}},[_vm._v("mdi-file-sign")])],1)]}}],null,false,2092400156)},[_c('span',[_vm._v("Check Signatures")])]):_vm._e(),_c('ActionResetUserPassword',{attrs:{"showDialogResetUserPassword":_vm.dialogResetUserPassword.show,"id":_vm.dialogResetUserPassword.id},on:{"update:showDialogResetUserPassword":function($event){return _vm.$set(_vm.dialogResetUserPassword, "show", $event)},"update:show-dialog-reset-user-password":function($event){return _vm.$set(_vm.dialogResetUserPassword, "show", $event)}}}),_c('ViewToDo',{attrs:{"showDialogViewToDo":_vm.dialogViewToDo.show,"id":_vm.dialogViewToDo.id},on:{"update:showDialogViewToDo":function($event){return _vm.$set(_vm.dialogViewToDo, "show", $event)},"update:show-dialog-view-to-do":function($event){return _vm.$set(_vm.dialogViewToDo, "show", $event)}}}),_c('AssignSalesPerson',{attrs:{"showDialogAssignSalesPerson":_vm.dialogAssignSalesPerson.show,"id":_vm.dialogAssignSalesPerson.id},on:{"methodToCall":_vm.dialogAssignSalesPerson.methodUpdate,"update:showDialogAssignSalesPerson":function($event){return _vm.$set(_vm.dialogAssignSalesPerson, "show", $event)},"update:show-dialog-assign-sales-person":function($event){return _vm.$set(_vm.dialogAssignSalesPerson, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }