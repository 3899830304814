import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"transition":"dialog-top-transition","persistent":"","width":"800","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showDialogAssignSalesPerson),callback:function ($$v) {_vm.showDialogAssignSalesPerson=$$v},expression:"showDialogAssignSalesPerson"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 lighten-2",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)"}},[_vm._v(" Assign Sales Person ")]),_c(VCardText,{staticStyle:{"margin-top":"20px"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('label',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.serviceBudgetDescription))]),_c('br'),_c('br'),(_vm.service != null && _vm.service.budgetControlUserRegister == 1)?_c('label',{staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.serviceUserRegister)+" ")]):_vm._e(),(_vm.service != null && _vm.service.budgetControlUserRegister == 1)?_c('br'):_vm._e(),(_vm.service != null && _vm.service.budgetControlUserRegister == 1)?_c('br'):_vm._e(),_c(VSimpleTable,{attrs:{"fixed-header":"","height":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Jobs in that city ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Sales Person Budget ")]),_c('th',{staticClass:"text-center"},[_vm._v(" Jobs in the week ")])])]),_c('tbody',_vm._l((_vm.listSellers),function(itemSeller,idx){return _c('tr',{key:itemSeller.id,class:{ selectedRow: _vm.selectedRow == idx },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleClick(idx, itemSeller.id)}}},[_c('td',[_vm._v(" "+_vm._s(itemSeller.userName)+" "),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(itemSeller.id == _vm.service.idLastResponsibleDeclined)?_c(VIcon,_vm._g({attrs:{"color":"orange","small":""}},on),[_vm._v("mdi-alert-outline")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("This user has declined this service before")])])],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(itemSeller.worksInTheRegion))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(itemSeller.budgetFormatted))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(itemSeller.worksInTheWeek))])])}),0),(_vm.service != null && _vm.service != undefined && _vm.service.userNameLastResponsibleDeclined != null && _vm.service.userNameLastResponsibleDeclined != undefined && _vm.service.userNameLastResponsibleDeclined.trim() != '' )?_c('tfoot',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"font-style":"italic","font-weight":"normal"}},[_vm._v(" This service was previously declined by "+_vm._s(_vm.service.userNameLastResponsibleDeclined)+" "),_c('br'),_vm._v(" Reason: "+_vm._s(_vm.service.reasonDeclined)+" ")])])]):_vm._e()]},proxy:true}])})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c(VBtn,{staticClass:"mx-2 v-btn-large",staticStyle:{"background-color":"var(--color__red) !important","color":"#ffffff !important"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"mx-2 v-btn-large",attrs:{"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Assign ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }