<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogAssignSalesPerson"
			transition="dialog-top-transition"
			persistent
			width="800"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Assign Sales Person
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label style="font-size: 20px;">{{ serviceBudgetDescription }}</label>
							<br />
							<br />
							<label 
								v-if="service != null && service.budgetControlUserRegister == 1"
								style="font-size: 20px;"
							>
								{{ serviceUserRegister }}
							</label>
							<br v-if="service != null && service.budgetControlUserRegister == 1" />
							<br v-if="service != null && service.budgetControlUserRegister == 1" />

							<v-simple-table
								fixed-header
								height="300px"
							>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">
												Name
											</th>
											<th class="text-center">
												Jobs in that city
											</th>
											<th class="text-center">
												Sales Person Budget
											</th>
											<th class="text-center">
												Jobs in the week
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											style="cursor: pointer;"
											v-for="(itemSeller, idx) in listSellers" :key="itemSeller.id"
											@click="handleClick(idx, itemSeller.id)"
											:class="{ selectedRow: selectedRow == idx }"
										>
											<td>
												{{ itemSeller.userName }}

												<v-tooltip left>
													<template v-slot:activator="{ on }">
														<v-icon 
															v-if="itemSeller.id == service.idLastResponsibleDeclined"
															v-on="on" 
															color="orange" 
															small
														>mdi-alert-outline</v-icon>
													</template>
													<span>This user has declined this service before</span>
												</v-tooltip>
											</td>
											<td class="text-center">{{ itemSeller.worksInTheRegion }}</td>
											<td class="text-center">{{ itemSeller.budgetFormatted }}</td>
											<td class="text-center">{{ itemSeller.worksInTheWeek }}</td>
										</tr>
									</tbody>
									<tfoot v-if="service != null && service != undefined && service.userNameLastResponsibleDeclined != null && service.userNameLastResponsibleDeclined != undefined && service.userNameLastResponsibleDeclined.trim() != '' ">
										<tr>
											<th class="text-left" style="font-style: italic; font-weight: normal;">
												This service was previously declined by {{ service.userNameLastResponsibleDeclined }} <br /> Reason: {{ service.reasonDeclined }}
											</th>
										</tr>
									</tfoot>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
                        <v-btn 
                            class="mx-2 v-btn-large"
                            @click="cancel"
                            style="background-color: var(--color__red) !important; color: #ffffff !important"
                        >
                            Cancel
                        </v-btn>

                        <v-btn  
                            class="mx-2 v-btn-large"
                            :loading="loading"
                            @click="save"
                        >
                            Assign
                        </v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';

	export default {

        mixins: [Helpers],

        props: {

			showDialogAssignSalesPerson: {
				default: false
			},
			
            id: {
                type: Number,
                default : 0
            },
		},

		
        data: () => ({

			service: null,
			loading: false,
            listSellers: [],
			idUserResponsible: 0,
            selectedRow: 0,
			serviceBudgetDescription: "",
			serviceUserRegister: "",
		}),

        watch: {
            async "id"() {
				this.listAllSalesPerson();
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        methods: {

            async listAllSalesPerson() {

				const response = await this.$store.dispatch("serviceModule/GetById", this.id);

				this.service = response.result;

				this.serviceBudgetDescription = `Job Budget: ${this.service.estimatedBudgetFormatted}`;
				this.serviceUserRegister = `Initially Assigned Sales Person: ${this.service.userNameRegister}`;

                const avaliableSellersRequest = {
                    idService: 0,
					idUser: 0,
                    startDate: "0001-01-01",
                    endDate: "0001-01-01",
                    budget: 0,
                    idCity: this.service.addressSelected.idCity,
					allSalesPerson: 1
                }

                this.listSellers = await this.$store.dispatch("serviceModule/ListAvaliableSellers", avaliableSellersRequest);
				const listSellersClone = [...this.listSellers];

                if (this.listSellers.length > 0) {

					if (this.service.budgetControlUserRegister == 1) {
						const itemSeller = listSellersClone.filter(sel => sel.id == this.service.idUserRegister)

						if (itemSeller != null && itemSeller != undefined && itemSeller.length > 0) {

							let idx = 0;
							listSellersClone.forEach(function(item, index) {
								
								if (item.id == itemSeller[0].id) {
									idx = index;
								}
							})

							this.listSellers.splice(idx, 1)
							this.listSellers.unshift(itemSeller[0]);
						}
					}
					
					this.idUserResponsible = this.listSellers[0].id
                }
            },

            handleClick(i, idUserResponsible) {
                this.selectedRow = i;
                this.idUserResponsible = idUserResponsible
            },
			
            async cancel() {				
				this.$emit('update:showDialogAssignSalesPerson', false)
            },

			async save() {
				if (this.idUserResponsible == 0) {
					this.showToast("error", "Warning!", "Please choose a sales person");
				}
				else {
					this.loading = true;
					this.showLoading();
					let id = this.id;
					let idUserResponsible = this.idUserResponsible;

					let result = await this.$store.dispatch("serviceModule/AssignSalesPerson", { id, idUserResponsible });

					if (result.success) {
						this.showToast("success", "Success!", result.message);
					}
					else {
						this.showToast("error", "Error!", result.message);
					}
					this.loading = false;
					this.hideLoading();
					this.cancel();
					
					this.$emit('methodToCall');
				}
			}
        }
    };
</script>


<style scoped>

    tbody > tr:hover {
        background-color: transparent !important;
    }

    .selectedRow, .selectedRow:hover {
        background-color: var(--color__main) !important;
        color: #fff;
        font-weight: bold;
    }

</style>